export const navHeight = 60
export const threePixelToUnitDivider = 1000
export const defaultProgramName = 'untitled.prog'

export const modelLifeCycles = {
  CREATED: 'created',
  BOOTING: 'booting',
  BOOTED: 'booted',
}

export const gridSaveStatus = {
  DEFAULT: 'default',
  SAVING: 'saving',
  SAVED: 'saved',
  ERROR: 'error',
}

export const messageTypes = {
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
}

export const subscriptionScopes = {
  ARTBOARD: 'artboard',
  COMPONENT: 'component',
  NONE: 'none',
}

export const settingTypes = {
  SLOT: 'slot',
  PREFIX: 'prefix',
  EXPRESSION: 'expression',
  IS_BREAK_VALUE_VISIBLE: 'isBreakValueVisible',
  IS_LABEL_VISIBLE: 'isLabelVisible',
  INPUT_TYPE: 'inputType',
  PATH: 'path',
  COLOR: 'color',
}

export const fsType = {
  FILE: 'file',
  DIRECTORY: 'directory',
  EXTERNAL: 'external',
}

export const colorScheme = {
  black: '#000000',
  gray1: '#191e27',
  gray2: '#28303e',
  gray3: '#323c4e',
  gray4: '#3c475d',
  gray5: '#46536d',
  gray6: '#8392af',
  gray7: '#a2adc3',
  white: '#ffffff',
  blue: '#43B6F1',
  blueLight: '#424956',
  redDark: '#F73022',
  orange: '#ee8a56',
  yellow: '#ECD32F',
  green: '#83c736',
  turqoise: '#50e3c1',
  lightGreen: '#A9EF5A',
  transparent: 'transparent',
}

export const componentTypes = {
  vButton: 'vgb-button',
  vSwitch: 'vgb-switch',
  vLight: 'vgb-light',
  vLabel: 'vgb-label',
  v3d: 'vgb-3d',
  vMiniPlot: 'vgb-miniplot',
  vDataPlot: 'vgb-dataplot',
  vDivider: 'vgb-divider',
  vOutput: 'vgb-output',
  vRange: 'vgb-range',
  vInput: 'vgb-input',
  vImage: 'vgb-image',
  vHtml: 'vgb-html',
  vFileList: 'vgb-filelist',
  vMeter: 'vgb-meter',
  vJoystick: 'vgb-joystick',
  vGeometry: 'vgb-geometry',
  vProgram: 'vgb-program',
  vCamera: 'vgb-camera',
  vColorpicker: 'vgb-colorpicker',
  vDropdown: 'vgb-dropdown',
}

export const inputComponentTypes = {
  input: 'v-input',
  checkbox: 'v-checkbox',
  radio: 'v-radio',
  colorPicker: 'v-color-picker',
  textarea: 'v-textarea',
  path: 'v-path',
  list: 'v-list',
  break: 'v-break',
  select: 'v-select',
  filter: 'v-filter',
  resource: 'v-resource',
  pathColor: 'v-path-color',
  pair: 'v-pair',
  number: 'v-number',
  range: 'v-range',
}

export const programElementTypes = {
  call: 'v-call',
  comment: 'v-comment',
  create: 'v-create',
  element: 'v-element',
  else: 'v-else',
  elseIf: 'v-elseif',
  group: 'v-group',
  if: 'v-if',
  loop: 'v-loop',
  move: 'v-move',
  root: 'v-root',
  set: 'v-set',
  stopLoop: 'v-stoploop',
  wait: 'v-wait',
}

export const defaultMainCreateCommandID = 'defaultMainCreateCommandID'
export const defaultMainCallCommandID = 'defaultMainCallCommandID'

export const variableTypes = {
  GEOMETRY: 'geometry',
  ARGUMENT: 'argument',
  ARGUMENT_CONSTRUCTOR: 'argument_constructor',
  VALUE: 'value',
  SET_LOCAL: 'set_local',
  SET_GLOBAL: 'set_global',
  CREATE: 'create',
}

export const libraryGroups = {
  ROBOT: 'robot',
  VARS: 'vars',
  SUBPROG: 'subprog',
  LOGIC: 'logic',
  FLOW: 'flow',
  META: 'meta',
}

export const argumentTypes = {
  VALUE: 'value',
  POINT: 'point',
}

export const motorCortexModes = {
  AUTO_M: 0,
  MANUAL_M: 1,
  AUTO_RUN: 2,
}

export let ModeCommands = {
  GOTO_INIT_E: 0,
  GOTO_PAUSE_E: 1,
  GOTO_AUTO_RUN_E: 2,
  GOTO_MANUAL_JOINT_MODE_E: 3,
  GOTO_MANUAL_CART_MODE_E: 4,
  GOTO_AUTO_RESET_E: 5,
  GOTO_AUTO_RUN_CART_MODE_E: 6,
  GOTO_AUTO_RUN_JOINT_MODE_E: 7,
  GOTO_TORQUE_MODE_E: 8,
  GOTO_SEMI_AUTO_E: 9,
}

export const motorCortexStates = {
  INIT_S: 0,
  OFF_S: 1,
  NEUTRAL_S: 2,
  ENGAGED_S: 3,
  FORCEDDISENGAGED_S: 4,
  ESTOP_CIRCUIT_OPENED_S: 5,
  ESTOP_OFF_S: 6,
  ESTOP_RESET_S: 7,
  SHUTDOWN_OFF_S: 8,

  OFF_TO_NEUTRAL_T: 102,
  NEUTRAL_TO_OFF_T: 201,
  NEUTRAL_TO_ENGAGED_T: 203,
  ENGAGED_TO_NEUTRAL_T: 302,
  TO_FORCED_DISENGAGED_T: 404,
  TO_SHUTDOWN_OFF_T: 808,
}

export const motorCortexStateEvents = {
  DO_NOTHING_E: -1,
  GOTO_OFF_E: 0,
  GOTO_PARK_E: 1,
  GOTO_NEUTRAL_E: 2,
  GOTO_ENGAGED_E: 3,
  ACKNOWLEDGE_ERROR: 255,
}

export const errorLevelMessages = {
  0: '-',
  1: 'In',
  2: 'Wa',
  3: 'FD',
  4: 'SD',
  5: 'ES',
}

export const keys = {
  BACKSPACE: 'Backspace',
  DELETE: 'Delete',
  C: 'c',
  COMMAND: 'Meta',
  CONTROL: 'Control',
  ESCAPE: 'Escape',
  SHIFT: 'Shift',
  V: 'v',
  Z: 'z',
}

export const keyCodes = {
  BACKSPACE: 8,
  DELETE: 46,
  CONTROL: 17,
  SHIFT: 16,
  LEFT_WINDOW_KEY: 91,
  C: 67,
  V: 86,
  ESCAPE: 27,
}

export const motionTypes = {
  TIMEANDACC: {
    value: 'time_and_acc',
    label: 'time and acc',
  },
  TIMEANDTIMEACC: {
    value: 'time_and_time_ecc',
    label: 'time and time ecc',
  },
  VELANDACC: {
    value: 'vel_and_cc',
    label: 'vel and cc',
  },
}

export const moveMotionAttributes = {
  V_MAX: 'vMax',
  A_MAX: 'aMax',
  OMEGA_MAX: 'omegaMax',
  ALFA_MAX: 'alfaMax',
  J_MAX: 'jMax',
  T_DURATION: 'tDuration',
  T_ACC: 'tAcc',
  ANGLE: 'angle',
}

export const moveTypes = {
  MOVE_L: {
    value: 'moveL',
    label: 'linear',
  },
  MOVE_J: {
    value: 'moveJ',
    label: 'joint',
  },
  MOVE_C: {
    value: 'moveC',
    label: 'circular',
  },
}

export const InterpreterEvents = {
  NEW_PROGRAM_E: 1,
  PLAY_PROGRAM_E: 2,
  MOVE_TO_START: 3,
  PAUSE_PROGRAM_E: 4,
  STOP_PROGRAM_E: 5,
  RESET_INTERPRETER_E: 6,
}

export const InterpreterStates = {
  PROGRAM_STOP_S: 0, // play
  PROGRAM_RUN_S: 1, // stop pause
  PROGRAM_PAUSE_S: 2, // play and stop
  MOTION_NOT_ALLOWED_S: 3, // move to start
}
