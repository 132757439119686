import Vue from 'vue'
import { threePixelToUnitDivider } from './constants'
import _clonedeep from 'lodash.clonedeep'
import VueRouter from 'vue-router'
import _get from 'lodash.get'

export function rad(degrees) {
  return (degrees / 180.0) * Math.PI
}

export function deg(radians) {
  return (radians * 180) / Math.PI
}

export function round(value, decimals) {
  decimals = parseInt(decimals)
  value = parseFloat(value)
  const pow = Math.pow(10, decimals)

  return (Math.round(value * pow) / pow).toFixed(decimals)
}

export function extractTouch(event) {
  return event.changedTouches && event.changedTouches.length
    ? event.changedTouches.item(event.changedTouches.length - 1)
    : event
}

export const isTouchEvent = (event) => {
  if (empty(event)) {
    return false
  }

  return 'touches' in event
}

export function constrain(value, min = null, max = null) {
  if (min !== null) {
    value = Math.max(value, min)
  }

  if (max !== null) {
    value = Math.min(value, max)
  }

  return value
}

export function threeUnit(value) {
  if (!value) {
    return 0
  }

  return value * threePixelToUnitDivider
}

export function applyFilter(value, filter) {
  return value * parseFloat(filter.settings.gain) + parseFloat(filter.settings.offset)
}

export function removeFilter(value, filter) {
  const result = (value - parseFloat(filter.settings.offset)) / parseFloat(filter.settings.gain)
  return isNaN(result) ? 0 : result
}

export function isObject(value) {
  return (
    value !== null && //
    typeof value === 'object' &&
    !Array.isArray(value)
  )
}

export function uuid() {
  let r = null
  let v = null

  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    r = (Math.random() * 16) | 0
    v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })

  return uuid
}

export function collisionDetectRectangles(a, b) {
  let collision = !(
    a.top + a.height < b.top ||
    a.top > b.top + b.height ||
    a.left + a.width < b.left ||
    a.left > b.left + b.width
  )

  return collision
}

export function componentPositionsToNumbers(dictionary) {
  Object.values(dictionary).forEach((dict) => {
    Object.values(dict.components).forEach((component) => {
      if (typeof component.x.value === 'string') {
        component.x.value = Number.parseInt(component.x.value)
      }
      if (typeof component.y.value === 'string') {
        component.y.value = Number.parseInt(component.y.value)
      }

      if (component.x.value > 3000 || component.y.value > 3000) {
        component.x.value = 0
        component.y.value = 0
      }
    })
  })

  return dictionary
}

export function empty(value) {
  return value === undefined || value === null || value === '' || (Array.isArray(value) && value.length === 0)
}

export function isValidJson(string) {
  try {
    return JSON.parse(string) && !!string
  } catch (e) {
    return false
  }
}

export function wrapInArray(value) {
  if (Array.isArray(value)) {
    return value
  }

  return [value]
}

export function cloneDeep(value) {
  return JSON.parse(JSON.stringify(value))
}

export function vueDeepSet(object, key, value) {
  const keys = key.split('.')
  let lastKey = keys.pop()

  keys.forEach((key) => {
    object = object[key]
  })

  Vue.set(object, lastKey, value)
}

export function slugify(str, settings = { allowUpperCase: false }) {
  if (!settings.allowUpperCase) {
    str = str.toLowerCase()
  }

  str = str.toString().trim()

  const sets = [
    { to: 'a', from: '[ÀÁÂÃÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]' },
    { to: 'ae', from: '[Ä]' },
    { to: 'c', from: '[ÇĆĈČ]' },
    { to: 'd', from: '[ÐĎĐÞ]' },
    { to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]' },
    { to: 'g', from: '[ĜĞĢǴ]' },
    { to: 'h', from: '[ĤḦ]' },
    { to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]' },
    { to: 'j', from: '[Ĵ]' },
    { to: 'ij', from: '[Ĳ]' },
    { to: 'k', from: '[Ķ]' },
    { to: 'l', from: '[ĹĻĽŁ]' },
    { to: 'm', from: '[Ḿ]' },
    { to: 'n', from: '[ÑŃŅŇ]' },
    { to: 'o', from: '[ÒÓÔÕØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]' },
    { to: 'oe', from: '[ŒÖ]' },
    { to: 'p', from: '[ṕ]' },
    { to: 'r', from: '[ŔŖŘ]' },
    { to: 's', from: '[ŚŜŞŠ]' },
    { to: 'ss', from: '[ß]' },
    { to: 't', from: '[ŢŤ]' },
    { to: 'u', from: '[ÙÚÛŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]' },
    { to: 'ue', from: '[Ü]' },
    { to: 'w', from: '[ẂŴẀẄ]' },
    { to: 'x', from: '[ẍ]' },
    { to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
    { to: 'z', from: '[ŹŻŽ]' },
    { to: '-', from: "[·/_,:;']" },
  ]

  sets.forEach((set) => {
    str = str.replace(new RegExp(set.from, 'gi'), set.to)
  })

  str = str
    .toString()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of str
    .replace(/-+$/, '') // Trim - from end of str

  if (typeof separator !== 'undefined' && separator !== '-') {
    str = str.replace(/-/g, separator)
  }

  return str
}

export function ucfirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function dotNotationToCamelCase(str) {
  return str
    .split('.')
    .map((element, index) => {
      return index === 0 ? element : ucfirst(element)
    })
    .join('')
}

export function concatPath(base, segment) {
  base = base.split('/')
  segment = segment.split('/')
  return base.concat(segment).join('/')
}

export function randomString(length = 10) {
  var str = ''
  var options = 'abcdefghijklmnopqrstuvwxyz'

  for (var i = 0; i < length; i++) {
    str += options.charAt(Math.floor(Math.random() * options.length))
  }

  return str
}

export function Uint8ToBase64(u8Arr) {
  const CHUNK_SIZE = 0x8000 // arbitrary number
  let index = 0
  const length = u8Arr.length
  let result = ''
  while (index < length) {
    const slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length))
    result += String.fromCharCode.apply(null, slice)
    index += CHUNK_SIZE
  }
  return btoa(result)
}

export function arraysContainSameValues(arr1, arr2) {
  if (empty(arr1)) {
    return false
  }

  if (empty(arr2)) {
    return false
  }

  if (!Array.isArray(arr1) || !Array.isArray(arr2) || arr1.length !== arr2.length) {
    return false
  }

  return arr1.every((value) => arr2.includes(value)) && arr2.every((value) => arr1.includes(value))
}

export function moveArrayElement(arr, slotFrom, slotTo) {
  arr = _clonedeep(arr)
  const element = arr[slotFrom]
  arr.splice(slotFrom, 1)
  arr.splice(slotTo, 0, element)
  return arr
}

export function isHexColor(hex) {
  return hex === '0' || (typeof hex === 'string' && hex.length === 6 && !isNaN(Number('0x' + hex)))
}

export function promiseTimeout(duration) {
  return new Promise((resolve) => {
    window.setTimeout(() => {
      resolve()
    }, duration)
  })
}

export function baseName(str) {
  var base = new String(str).substring(str.lastIndexOf('/') + 1)
  if (base.lastIndexOf('.') != -1) base = base.substring(0, base.lastIndexOf('.'))
  return base
}

export function dirName(str) {
  return new String(str).substring(0, str.indexOf('/') + 1)
}

export function trim(s, c) {
  if (c === ']') c = '\\]'
  if (c === '\\') c = '\\\\'
  return s.replace(new RegExp('^[' + c + ']+|[' + c + ']+$', 'g'), '')
}

export function isStringValidArray(value) {
  let result = false

  try {
    result = Array.isArray(JSON.parse(value))
  } catch (e) {
    result = false
  }

  return result
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function addDragClassToBody() {
  document.body.classList.add('dragging')
}

export function removeDragClassFromBody() {
  document.body.classList.remove('dragging')
}

export function isValueObject(model, attribute) {
  const obj = _get(model, attribute)
  return obj !== null && typeof obj === 'object' && obj.hasOwnProperty('value') && obj.value !== undefined
}

export function handleRouteError(error) {
  const { isNavigationFailure, NavigationFailureType } = VueRouter
  const isRedirectError = isNavigationFailure(error, NavigationFailureType.redirected)

  if (!isRedirectError) {
    throw error
  }
}

export function isControlInUse(el, mouseMoveEvent, boundingBoxFactor = 0.4) {
  const { x, y, width, height } = el.getBoundingClientRect()

  const rect = {
    top: y - height * boundingBoxFactor,
    right: x + width + width * boundingBoxFactor,
    bottom: y + height + height * boundingBoxFactor,
    left: x - width * boundingBoxFactor,
  }
  const mouseX = mouseMoveEvent.clientX
  const mouseY = mouseMoveEvent.clientY
  const isMouseInRect = mouseX > rect.left && mouseX < rect.right && mouseY > rect.top && mouseY < rect.bottom

  return document.hasFocus && isMouseInRect
}

export function cleanPath(path) {
  path = path.trim()

  if (path.substring(0, 2) === './') {
    path = path.substring(2)
  }

  return path
    .split('/')
    .map((element) => {
      return element.replace(/([^a-z0-9?=\s_-]]+)/i, '')
    })
    .filter((element) => {
      return element && element.length > 0
    })
    .join('/')
}

export function formatControllerTime(datetime) {
  const input = datetime.replace(/-/g, ':').replace(/ /g, ':').replace('.', ':').split(':')

  const d = new Date(
    Date.UTC(
      parseInt(input[0]),
      parseInt(input[1]) - 1,
      parseInt(input[2]),
      parseInt(input[3]),
      parseInt(input[4]),
      parseInt(input[5]),
      parseInt(input[6]),
    ),
  )

  const year = d.getFullYear()
  const month = ('0' + (d.getMonth() + 1)).slice(-2)
  const day = ('0' + d.getDate()).slice(-2)
  const hours = ('0' + d.getHours()).slice(-2)
  const minutes = ('0' + d.getMinutes()).slice(-2)
  const seconds = ('0' + d.getSeconds()).slice(-2)
  const milliseconds = ('0' + d.getMilliseconds()).slice(-3)
  const result = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`

  return result
}

export function arrayWrap(value) {
  if (Array.isArray(value)) {
    return value
  }

  return [value]
}

export function namespace(value) {
  return `__${value}__`
}

export function waitForDomElement(selector, interval = 5, maxTries = 100) {
  let tries = 0

  return new Promise((resolve, reject) => {
    function query() {
      const clone = document.querySelector(selector)

      if (clone) {
        clearInterval(intervalID)
        resolve(clone)
      }

      if (tries >= maxTries) {
        clearInterval(intervalID)
        reject()
      }

      tries++
    }

    const intervalID = setInterval(() => {
      query()
    }, interval)

    query()
  })
}

export function yieldToMain() {
  return new Promise((resolve) => {
    setTimeout(resolve, 0)
  })
}
